
.post-col-wrapper {
  @extend %clearfix;
  margin-bottom: $spacing-unit;
}

.post-col {
  float: left;
  padding-left: $spacing-unit / 2;

}

.post-col1 {
  width: -webkit-calc(30% - (#{$spacing-unit} / 2));
  width: calc(30% - (#{$spacing-unit} / 2));
  margin-top: 10px;
}

.post-col2 {
  width: -webkit-calc(70% - (#{$spacing-unit} / 2));
  width: calc(70% - (#{$spacing-unit} / 2));
}

@include media-query($on-palm) {

  .post-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }

}

@include media-query($on-laptop) {
  .post-col1 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width: calc(30% - (#{$spacing-unit} / 2));
    margin-top: 10px;
  }

  .post-col2 {
    width: -webkit-calc(80% - (#{$spacing-unit} / 2));
    width: calc(70% - (#{$spacing-unit} / 2));
  }
}

.row .date {
  font-size: $base-font-size;
}

.row .title {
  font-size: -webkit-calc(#{$base-font-size} * 1.5);
  font-size: calc(#{$base-font-size} * 1.5);
}

.row .preview {
  font-size: $base-font-size;
}